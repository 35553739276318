import {call, ForkEffect, put, takeEvery} from 'redux-saga/effects';
import {loginApi, currentUserApi, userApi} from 'configApi';
import {actions} from './slice';

// TODO: later on when have api, make sure to use our axios file
import {apiCall} from '../../utils/axios';
import {setSession} from '../../utils/jwt';

function* getCurrentUserSaga() {
  yield put(actions.getCurrentUserPending());
  const {response, error} = yield call(apiCall, currentUserApi, 'get');

  if (
    response &&
    response.data &&
    response.data.status &&
    // have to .toLowerCase() because for this endpoint back-end return "Fail" / "Success"
    response.data.status.toLowerCase() === 'fail'
  ) {
    if (
      response.data.message &&
      response.data.message.toLowerCase() === 'invalid token.'
    ) {
      // technically the only time that invalid token can appear is when someone else logged in using the same account
      // so this will suffice for now
      // also, "Invalid token." string is set up in axios in front-end as well.
      // TODO: make sure it is the case.
      yield put(
        actions.getCurrentUserFail({
          error: 'Multiple logins are not supported in this release.',
        })
      );
    } else {
      yield put(actions.getCurrentUserFail({error: response.data.message}));
    }

    setSession(null);
  } else if (
    response &&
    response.data &&
    response.data.status &&
    // have to .toLowerCase() because for this endpoint back-end return "Fail" / "Success"
    response.data.status.toLowerCase() === 'success'
  ) {
    // TODO: please put more check in cases any property is null / not returned !!
    const userData = response.data.data;
    const user = {
      id: userData.id,
      country: '',
      email: userData.attributes.email,
      company: userData.attributes.company,
      name: userData.attributes.first_name + ' ' + userData.attributes.last_name,
      displayName: userData.attributes.first_name,
      firstName: userData.attributes.first_name,
      lastName: userData.attributes.last_name,
      cover: '',
      position: '',
      folower: 0,
      following: 0,
      quote: '',
      school: '',
      role: '',
      facebookLink: '',
      instagramLink: '',
      linkedinLink: '',
      twitterLink: '',
      about: '',
      address: '',
      city: '',
      isPublic: true,
      password: '',
      phoneNumber: '',
      photoURL: '',
      state: '',
    };
    yield put(actions.getCurrentUserSuccess(user));
  } else {
    setSession(null);
    yield put(actions.getCurrentUserFail({error: response.data.message}));
  }
}

function* postLoginSaga(action) {
  const endPoint = loginApi;

  yield put(actions.postLoginPending());
  const {email, password} = action.payload;
  const postObj: any = {
    field: {
      email: email,
      password: password,
    },
  };
  const {response, error} = yield call(apiCall, endPoint, 'post', postObj);
  // axios.get(endPoint).then(function (response) {
  //   console.log(response.data);
  // });

  if (response && response.data && response.data.status === 'success') {
    const {jwt_token} = response.data.data;
    setSession(jwt_token);

    const userResponse = yield call(apiCall, currentUserApi, 'get');
    const userData = userResponse.response.data.data;
    const user = {
      id: userData.id,
      country: '',
      email: userData.attributes.email,
      company: userData.attributes.company,
      name: userData.attributes.first_name + ' ' + userData.attributes.last_name,
      displayName: userData.attributes.first_name,
      firstName: userData.attributes.first_name,
      lastName: userData.attributes.last_name,
      cover: '',
      position: '',
      folower: 0,
      following: 0,
      quote: '',
      school: '',
      role: '',
      facebookLink: '',
      instagramLink: '',
      linkedinLink: '',
      twitterLink: '',
      about: '',
      address: '',
      city: '',
      isPublic: true,
      password: '',
      phoneNumber: '',
      photoURL: '',
      state: '',
    };
    yield put(actions.postLoginSuccess(user));
  } else {
    yield put(actions.postLoginFail({error: response.data.message}));
    setSession(null);
  }
}

function* postForgotPasswordEmailSaga(action) {
  const endPoint = userApi + '/forgot_password';

  yield put(actions.postForgotPasswordEmailPending());
  const postObj: any = {
    field: action.payload,
  };
  const {response, error} = yield call(apiCall, endPoint, 'post', postObj);
  if (response && response.data) {
    if (response.data.status === 'success') {
      yield put(actions.postForgotPasswordEmailSuccess());
    } else if (response.data.status === 'fail' && response.data.message) {
      yield put(actions.postForgotPasswordEmailFail({error: response.data.message}));
    } else {
      yield put(actions.postForgotPasswordEmailFail({error: 'Something went wrong'}));
    }
  } else {
    yield put(actions.postForgotPasswordEmailFail({error: 'Something went wrong'}));
  }
}

function* postResetPasswordSaga(action) {
  const endPoint = userApi + '/reset_password';

  yield put(actions.postResetPasswordPending());
  const postObj: any = {
    field: action.payload,
  };
  const {response, error} = yield call(apiCall, endPoint, 'post', postObj);

  if (response && response.data) {
    if (response.data.status === 'success') {
      yield put(actions.postResetPasswordSuccess());
    } else if (response.data.status === 'fail' && response.data.message) {
      yield put(actions.postResetPasswordFail({error: response.data.message}));
    } else {
      yield put(actions.postResetPasswordFail({error: 'Something went wrong'}));
    }
  } else {
    yield put(actions.postResetPasswordFail({error: 'Something went wrong'}));
  }
}

export function* authSaga(): Generator<ForkEffect<never>, void, unknown> {
  yield takeEvery(actions.getCurrentUserStart.type, getCurrentUserSaga);
  yield takeEvery(actions.postLoginStart.type, postLoginSaga);
  yield takeEvery(actions.postForgotPasswordEmailStart.type, postForgotPasswordEmailSaga);
  yield takeEvery(actions.postResetPasswordStart.type, postResetPasswordSaga);
}
