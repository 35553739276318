import React from 'react';
import {useEffect} from 'react';
import {Link as RouterLink, useLocation} from 'react-router-dom';
// material
import {alpha, styled} from '@material-ui/core/styles';
import {
  Box,
  Link,
  Stack,
  Drawer,
  Tooltip,
  Typography,
  CardActionArea,
  Backdrop,
  CircularProgress,
  Button,
} from '@material-ui/core';
import {Icon} from '@iconify/react';
import {plusFill} from '../../icons';

// hooks
import useAuth from '../../hooks/useAuth';
import useCollapseDrawer from '../../hooks/useCollapseDrawer';
import {useSelector, RootStateOrAny} from 'react-redux';
// routes
import {PATH_DASHBOARD} from '../../routes/paths';
// components
import LogoDashboard from '../../components/LogoDashboard';
import MyAvatar from '../../components/MyAvatar';
import Scrollbar from '../../components/Scrollbar';
import NavSection from '../../components/NavSection';
import {MHidden} from '../../components/@material-extend';
import SidebarTicketButton from './SidebarTicketButton';
//
import sidebarConfig from './SidebarConfig';

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280;
const COLLAPSE_WIDTH = 102;

const RootStyle = styled('div')(({theme}) => ({
  [theme.breakpoints.up('lg')]: {
    flexShrink: 0,
    transition: theme.transitions.create('width', {
      duration: theme.transitions.duration.complex,
    }),
  },
}));

const AccountStyle = styled('div')(({theme}) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2, 2.5),
  borderRadius: theme.shape.borderRadiusSm,
  backgroundColor: theme.palette.grey[500_12],
}));

// ----------------------------------------------------------------------

function IconCollapse({onToggleCollapse, collapseClick}) {
  return (
    <Tooltip title="Mini Menu">
      <CardActionArea
        onClick={onToggleCollapse}
        sx={{
          width: 18,
          height: 18,
          display: 'flex',
          cursor: 'pointer',
          borderRadius: '50%',
          alignItems: 'center',
          color: 'text.primary',
          justifyContent: 'center',
          border: 'solid 1px currentColor',
          ...(collapseClick && {
            borderWidth: 2,
          }),
        }}
      >
        <Box
          sx={{
            width: 8,
            height: 8,
            borderRadius: '50%',
            bgcolor: 'currentColor',
            transition: theme => theme.transitions.create('all'),
            ...(collapseClick && {
              width: 0,
              height: 0,
            }),
          }}
        />
      </CardActionArea>
    </Tooltip>
  );
}

interface ChildProps {
  onCloseSidebar: () => void;
  isOpenSidebar: boolean;
}

const DashboardSidebar: React.FC<ChildProps> = ({isOpenSidebar, onCloseSidebar}) => {
  const {pathname} = useLocation();
  const {user} = useAuth();

  // redux
  const pendingTemplateRequest = useSelector(
    ({template}: RootStateOrAny) => template.template.requests.pendingCount
  );

  const {
    isCollapse,
    collapseClick,
    collapseHover,
    onToggleCollapse,
    onHoverEnter,
    onHoverLeave,
  } = useCollapseDrawer();

  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
  }, [pathname]);

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        '& .simplebar-content': {
          height: 1,
          display: 'flex',
          flexDirection: 'column',
        },
        // for backdrop
        position: 'relative',
        borderRight: '1px solid #ccc',
      }}
    >
      {/* Logo & Profile section */}
      <Stack
        spacing={3}
        sx={{
          px: 2.5,
          pt: 3,
          pb: 2,
          ...(isCollapse && {
            alignItems: 'center',
          }),
        }}
      >
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Box component={RouterLink} to="/" sx={{display: 'inline-flex'}}>
            <LogoDashboard />
          </Box>

          {/* This will break the login because we don't have the short version of the logo */}
          {/* So remove for now */}
          {/* <MHidden width="lgDown">
            {!isCollapse && (
              <IconCollapse
                onToggleCollapse={onToggleCollapse}
                collapseClick={collapseClick}
              />
            )}
          </MHidden> */}
        </Stack>

        {isCollapse ? (
          <MyAvatar sx={{mx: 'auto', mb: 2}} />
        ) : (
          <Link
            underline="none"
            component={RouterLink}
            to={PATH_DASHBOARD.settings}
            onClick={event => event.preventDefault()}
          >
            <AccountStyle>
              <MyAvatar />
              <Box sx={{ml: 2}}>
                <Typography variant="subtitle2" sx={{color: 'text.primary'}}>
                  {user?.displayName}
                </Typography>
                {user?.company && (
                  <Typography variant="body2" sx={{color: 'text.secondary'}}>
                    {user?.company}
                  </Typography>
                )}
                {/* <Typography variant="body2" sx={{color: 'text.secondary'}}>
                  {user?.role}
                </Typography> */}
              </Box>
            </AccountStyle>
          </Link>
        )}
      </Stack>
      {/* Main sidebar links */}
      <NavSection navConfig={sidebarConfig} isShow={!isCollapse} />
      <Box sx={{flexGrow: 1}} />
      {/* submit ticket button + ticket form dialog  */}
      <SidebarTicketButton />
      <Backdrop
        open={pendingTemplateRequest !== 0}
        sx={{
          position: 'absolute',
          background: 'rgba(255, 255, 255, 0.5)',
        }}
      />
    </Scrollbar>
  );

  return (
    <RootStyle
      sx={{
        width: {
          lg: isCollapse ? COLLAPSE_WIDTH : DRAWER_WIDTH,
        },
        ...(collapseClick && {
          position: 'absolute',
        }),
        position: 'relative',
      }}
    >
      <MHidden width="lgUp">
        <Drawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          PaperProps={{
            sx: {width: DRAWER_WIDTH},
          }}
        >
          {renderContent}
        </Drawer>
      </MHidden>

      <MHidden width="lgDown">
        <Drawer
          open
          variant="persistent"
          onMouseEnter={onHoverEnter}
          onMouseLeave={onHoverLeave}
          PaperProps={{
            sx: {
              width: DRAWER_WIDTH,
              bgcolor: 'background.default',
              ...(isCollapse && {
                width: COLLAPSE_WIDTH,
              }),
              ...(collapseHover && {
                borderRight: 0,
                backdropFilter: 'blur(6px)',
                WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
                boxShadow: theme => theme.customShadows.z20,
                bgcolor: theme => alpha(theme.palette.background.default, 0.88),
              }),
            },
          }}
        >
          {renderContent}
        </Drawer>
      </MHidden>
    </RootStyle>
  );
};

export default DashboardSidebar;
