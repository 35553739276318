import {ForkEffect, spawn} from 'redux-saga/effects';

import {authSaga} from 'store/auth/saga';
import {registerSaga} from 'store/register/saga';
import {templateSaga} from 'store/template/saga';
import {companySaga} from 'store/company/saga';
import {locationSaga} from 'store/location/saga';
import {inspectionSaga} from 'store/inspection/saga';
import {accSettingsSaga} from 'store/accountSettings/saga';
import {reportSaga} from 'store/report/saga';

export function* rootSaga(): Generator<ForkEffect<void>, void, unknown> {
  yield spawn(authSaga);
  yield spawn(registerSaga);
  yield spawn(templateSaga);
  yield spawn(companySaga);
  yield spawn(locationSaga);
  yield spawn(inspectionSaga);
  yield spawn(accSettingsSaga);
  yield spawn(reportSaga);
}
