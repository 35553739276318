import {call, ForkEffect, put, takeLatest} from 'redux-saga/effects';
import {inspectionApi} from 'configApi';
import {actions} from './slice';
import {apiCall} from '../../utils/axios';

// report view page
function* getReportByInspectionIdSaga(action) {
  const {id, partial} = action.payload;
  const endPoint = inspectionApi + '/' + id + '/report?partial=' + partial;

  yield put(actions.getReportByInspectionIdPending());
  const {response, error} = yield call(apiCall, endPoint, 'get');

  if (response && response.status === 200 && response.data) {
    // this is wrong but will work for now.
    if (response.data.status === 'error') {
      if (
        response.data.message &&
        response.data.message.toLowerCase() === 'inspection not completed'
      ) {
        yield put(
          actions.getReportByInspectionIdFail({
            error: {message: 'The inspection has not been completed yet.'},
          })
        );
      } else if (
        response.data.message &&
        response.data.message.toLowerCase() === 'no company info found'
      ) {
        yield put(
          actions.getReportByInspectionIdFail({
            error: {
              message: "Please add location's company details or update your settings.",
            },
          })
        );
      } else {
        yield put(
          actions.getReportByInspectionIdFail({
            error: {message: 'Oops. There was problem loading the report.'},
          })
        );
      }
    } else {
      yield put(actions.getReportByInspectionIdSuccess({data: response.data}));
    }
  } else if (error) {
    yield put(
      actions.getReportByInspectionIdFail({
        error: {
          message: 'Oops. There was problem loading the report.something went wrong',
        },
      })
    );
  }
}

export function* reportSaga(): Generator<ForkEffect<never>, void, unknown> {
  yield takeLatest(
    actions.getReportByInspectionIdStart.type,
    getReportByInspectionIdSaga
  );
}
