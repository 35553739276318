import React, {Suspense, lazy} from 'react';
import {Navigate, useRoutes, useLocation} from 'react-router-dom';
// layouts
import MainLayout from '../layouts/main';
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// guards
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';
// import RoleBasedGuard from '../guards/RoleBasedGuard';
// components
import LoadingScreen from '../components/LoadingScreen';

// ----------------------------------------------------------------------
// eslint-disable-next-line react/display-name
const Loadable = Component => props => {
  const {pathname} = useLocation();
  const isDashboard = pathname.includes('/dashboard');

  return (
    <Suspense
      fallback={
        <LoadingScreen
          sx={{
            ...(!isDashboard && {
              top: 0,
              left: 0,
              width: 1,
              zIndex: 9999,
              position: 'fixed',
            }),
          }}
        />
      }
    >
      <Component {...props} />
    </Suspense>
  );
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default function Router() {
  return useRoutes([
    {
      path: 'login',
      element: (
        <GuestGuard>
          <Login />
        </GuestGuard>
      ),
    },
    {
      path: 'register',
      element: (
        <GuestGuard>
          <Register />
        </GuestGuard>
      ),
    },
    {
      path: 'register-success',
      element: (
        <GuestGuard>
          <RegisterConfirmation />
        </GuestGuard>
      ),
    },
    {
      path: 'forgot-password',
      element: (
        <GuestGuard>
          <ForgotPassword />
        </GuestGuard>
      ),
    },
    {
      path: 'reset-password',
      element: (
        <GuestGuard>
          <ResetPassword />
        </GuestGuard>
      ),
    },

    // {path: 'login-unprotected', element: <Login />},
    // {path: 'register-unprotected', element: <Register />},
    // {path: 'verify', element: <VerifyCode />},

    // Dashboard Route
    {
      path: 'dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        {path: '', element: <Navigate to="/dashboard/home" replace />},
        {path: 'home', element: <GeneralApp />},
        {
          path: 'templates',
          children: [
            {path: '', element: <TemplatesPage />},
            {path: 'item/:id', element: <TemplatePage />},
            {path: 'new', element: <TemplateNewPage />},
          ],
        },
        {
          path: 'inspections',
          children: [{path: '', element: <InspectionsPage />}],
        },
        {path: 'actions', element: <ActionsPage />},
        {path: 'users', element: <UsersPage />},
        {
          path: 'companies',
          children: [
            {path: '', element: <CompaniesPage />},
            {path: 'new', element: <CompanyNewPage />},
            {path: 'item/:id', element: <CompanyPage />},
          ],
        },
        {
          path: 'locations',
          children: [
            {path: '', element: <LocationsPage />},
            {path: 'new', element: <LocationNewPage />},
            {path: 'item/:id', element: <LocationPage />},
          ],
        },
        {path: 'settings', element: <SettingsPage />},
        // NOTE: will use this again when we actually has the payment setting page
        // {
        //   path: 'settings',
        //   children: [
        //     {path: '', element: <Navigate to="/dashboard/settings/personal" replace />},
        //     {path: 'personal', element: <PersonalSettingsPage />},
        //     {path: 'payment', element: <PaymentSettingsPage />},
        //   ],
        // },
      ],
    },

    // Inspection route
    {
      path: 'dashboard/inspections/item/:id',
      element: (
        <AuthGuard>
          <InspectionPage />
        </AuthGuard>
      ),
    },

    // Report route (Public for now)
    {
      path: 'inspection/report/:id',
      element: <ReportPage />,
    },

    // Main Routes
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        {path: 'coming-soon', element: <ComingSoon />},
        {path: 'maintenance', element: <Maintenance />},
        // {path: 'pricing', element: <Pricing />},
        // {path: 'payment', element: <Payment />},
        {path: '500', element: <Page500 />},
        {path: '404', element: <NotFound />},
        {path: '*', element: <Navigate to="/404" replace />},
      ],
    },
    {
      path: '',
      element: <Navigate to="/login" replace />,
    },
    // {
    //   path: '',
    //   element: <MainLayout />,
    //   children: [
    //     {path: '', element: <LandingPage />},
    //     {path: 'about-us', element: <About />},
    //     {path: 'faqs', element: <Faqs />},
    //   ],
    // },
    {path: '*', element: <Navigate to="/404" replace />},
  ]);
}

// IMPORT COMPONENTS

// Authentication
const Login = Loadable(lazy(() => import('../pages/authentication/Login')));
const Register = Loadable(lazy(() => import('../pages/authentication/Register')));
const RegisterConfirmation = Loadable(
  lazy(() => import('../pages/authentication/RegisterConfirmationPage'))
);
const ForgotPassword = Loadable(
  lazy(() => import('../pages/authentication/ForgotPassword'))
);
const ResetPassword = Loadable(
  lazy(() => import('../pages/authentication/ResetPassword'))
);
const VerifyCode = Loadable(
  lazy(() => import('../minimal/pages/authentication/VerifyCode'))
);
// Dashboard
const GeneralApp = Loadable(lazy(() => import('../pages/dashboard/GeneralApp')));
const TemplatesPage = Loadable(
  lazy(() => import('../pages/dashboard/template/TemplatesPage'))
);
const TemplateNewPage = Loadable(
  lazy(() => import('../pages/dashboard/template/TemplateNewPage'))
);
const TemplatePage = Loadable(
  lazy(() => import('../pages/dashboard/template/TemplatePage'))
);
const InspectionsPage = Loadable(
  lazy(() => import('../pages/dashboard/inspection/InspectionsPage'))
);
const InspectionPage = Loadable(
  lazy(() => import('../pages/dashboard/inspection/InspectionPage'))
);
const ActionsPage = Loadable(lazy(() => import('../pages/dashboard/ActionsPage')));
const UsersPage = Loadable(lazy(() => import('../pages/dashboard/UsersPage')));
const CompaniesPage = Loadable(
  lazy(() => import('../pages/dashboard/company/CompaniesPage'))
);
const CompanyNewPage = Loadable(
  lazy(() => import('../pages/dashboard/company/CompanyNewPage'))
);
const CompanyPage = Loadable(
  lazy(() => import('../pages/dashboard/company/CompanyPage'))
);
const LocationsPage = Loadable(
  lazy(() => import('../pages/dashboard/location/LocationsPage'))
);
const LocationNewPage = Loadable(
  lazy(() => import('../pages/dashboard/location/LocationNewPage'))
);
const LocationPage = Loadable(
  lazy(() => import('../pages/dashboard/location/LocationPage'))
);
const SettingsPage = Loadable(
  lazy(() => import('../pages/dashboard/account/SettingsPage'))
);
const ReportPage = Loadable(lazy(() => import('../pages/ReportPage')));
// const PersonalSettingsPage = Loadable(
//   lazy(() => import('../pages/dashboard/account/PersonalSettingsPage'))
// );
// const PaymentSettingsPage = Loadable(
//   lazy(() => import('../pages/dashboard/account/PaymentSettingsPage'))
// );

// Main
const ComingSoon = Loadable(lazy(() => import('../pages/ComingSoon')));
const Maintenance = Loadable(lazy(() => import('../pages/Maintenance')));
const Page500 = Loadable(lazy(() => import('../pages/Page500')));
const NotFound = Loadable(lazy(() => import('../pages/Page404')));
// const LandingPage = Loadable(lazy(() => import('../minimal/pages/LandingPage')));
// const About = Loadable(lazy(() => import('../minimal/pages/About')));
// const Faqs = Loadable(lazy(() => import('../minimal/pages/Faqs')));
// const Pricing = Loadable(lazy(() => import('../minimal/pages/Pricing')));
// const Payment = Loadable(lazy(() => import('../minimal/pages/Payment')));
