import * as _ from 'lodash';
import {createSlice, PayloadAction} from '@reduxjs/toolkit';

const initialState = {
  report: {
    inspectionId: null,
    pending: false,
    data: null,
    error: null,
    success: false,
    partial: false,
  },
};

// report slice
const reportSlice = createSlice({
  name: 'report',
  initialState,
  reducers: {
    // report view page
    getReportByInspectionIdStart: (state, action) => {
      const {id, partial} = action.payload;
      state.report = {
        ...initialState.report,
        inspectionId: id,
        partial,
      };
    },
    getReportByInspectionIdPending: state => {
      state.report.pending = true;
    },
    getReportByInspectionIdFail: (state, action) => {
      const {error} = action.payload;
      state.report = {
        ...state.report,
        pending: false,
        data: null,
        error: error,
        success: false,
        partial: false,
      };
    },
    getReportByInspectionIdSuccess: (state, action) => {
      const {data} = action.payload;
      state.report = {
        ...state.report,
        pending: false,
        data: data,
        error: null,
        success: true,
        partial: false,
      };
    },
    resetReportState: state => {
      state.report = initialState.report;
    },
  },
});

const {actions, reducer} = reportSlice;
export {actions, reducer};
