import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {AuthStateType, User} from './types';

const initialState: AuthStateType = {
  user: null,
  auth: {
    loading: false,
    error: null,
  },
  login: {
    loading: false,
    error: null,
  },
  forgotPassword: {
    loading: false,
    error: null,
    success: false,
  },
  resetPassword: {
    loading: false,
    error: null,
    success: false,
  },
  triggerLogOutBecauseOfInvalidAccessToken: 0,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    getCurrentUserStart: (state: AuthStateType) => {},
    getCurrentUserPending: state => {
      // can do both spread and non spread
      // thanks to immer library built in redux-toolkit
      // option 1
      state.user = null;
      state.auth.loading = true;
      state.auth.error = null;
      // option 2
      // return {
      //   ...state,
      //   user: null,
      //   auth: {
      //     ...state.auth,
      //     loading: true,
      //     error: null,
      //   },
      // };
    },
    getCurrentUserFail: (state: AuthStateType, action: PayloadAction<any>) => {
      const {error} = action.payload;
      state.user = null;
      state.auth.loading = false;
      state.auth.error = error;
    },
    getCurrentUserSuccess: (state: AuthStateType, action: PayloadAction<User>) => {
      state.user = action.payload;
      state.auth.loading = false;
      state.auth.error = null;
    },
    postLoginStart: (
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      state: AuthStateType,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      action: PayloadAction<{email: string; password: string}>
    ) => {},
    postLoginPending: state => {
      state.user = null;
      state.auth.loading = true;
      state.auth.error = null;
      state.login.loading = true;
      state.login.error = null;
    },
    postLoginFail: (state: AuthStateType, action: PayloadAction<any>) => {
      // console.log(JSON.stringify(action.payload.error));
      state.user = null;
      state.auth.loading = false;
      state.auth.error = action.payload.error;
      state.login.loading = false;
      state.login.error = action.payload.error;
    },
    postLoginSuccess: (state: AuthStateType, action: PayloadAction<User>) => {
      state.user = action.payload;
      state.auth.loading = false;
      state.auth.error = null;
      state.login.loading = false;
      state.login.error = null;
    },
    postForgotPasswordEmailStart: (state, action) => {},
    postForgotPasswordEmailPending: state => {
      state.forgotPassword.loading = true;
    },
    postForgotPasswordEmailFail: (state, action) => {
      const {error} = action.payload;
      state.forgotPassword = {
        ...state.forgotPassword,
        loading: false,
        error: error,
        success: false,
      };
    },
    postForgotPasswordEmailSuccess: state => {
      state.forgotPassword = {
        ...state.forgotPassword,
        loading: false,
        error: null,
        success: true,
      };
    },
    resetForgotPasswordState: state => {
      state.forgotPassword = {
        ...state.forgotPassword.error,
        ...initialState.forgotPassword,
      };
    },
    postResetPasswordStart: (state, action) => {},
    postResetPasswordPending: state => {
      state.resetPassword.loading = true;
    },
    postResetPasswordFail: (state, action) => {
      const {error} = action.payload;
      state.resetPassword = {
        ...state.resetPassword,
        loading: false,
        error: error,
        success: false,
      };
    },
    postResetPasswordSuccess: state => {
      state.resetPassword = {
        ...state.resetPassword,
        loading: false,
        error: null,
        success: true,
      };
    },
    resetResetPasswordState: state => {
      state.resetPassword = {
        ...state.resetPassword.error,
        ...initialState.resetPassword,
      };
    },
    triggerLogOutBecauseOfInvalidAccessToken: state => {
      state.triggerLogOutBecauseOfInvalidAccessToken =
        state.triggerLogOutBecauseOfInvalidAccessToken + 1;
    },
  },
});

const {actions, reducer} = authSlice;

export {actions, reducer};
