import React from 'react';
import {createContext, useEffect, useState} from 'react';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
// utils
import {setSession} from '../utils/jwt';
// react-router
import {useNavigate} from 'react-router-dom';

// auth redux
import {actions} from 'store/auth/slice';
import {RootState} from 'store/rootReducer';
import {User} from 'store/auth/types';

// import axios from '../minimal/utils/axios';

// ----------------------------------------------------------------------

type initialStateType = {
  isAuthenticated: boolean;
  isInitialized: boolean;
  user: null | User;
  error: null | any;
  resetPasswordError: null | any;
};

export type authProviderType = initialStateType & {
  onLogout: () => void;
  onLogin: (email: string, password: string) => void;
  onResetPassword: (password: string) => void;
};

const initialState: initialStateType = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
  error: null,
  resetPasswordError: null,
};

const AuthContext = createContext<authProviderType>({
  ...initialState,
  onLogout: () => {},
  onLogin: () => {},
  onResetPassword: () => {},
});

const AuthProvider: React.FC = ({children}) => {
  // const [state, dispatch] = useReducer(reducer, initialState);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isInitialized, setIsInitialized] = useState(false);
  const [user, setUser] = useState<null | User>(null);
  const [error, setError] = useState<null | any>(null);
  const [resetPasswordError /*, setResetPasswordError */] = useState<null | any>(null);
  const {
    ['user']: userStore,
    auth,
    login,
  } = useSelector((state: RootState) => state.auth, shallowEqual);

  const triggerLogOutBecauseOfInvalidAccessToken = useSelector(
    (state: RootState) => state.auth.triggerLogOutBecauseOfInvalidAccessToken
  );

  // only first run when the component initialized
  useEffect(() => {
    const accessToken = window.localStorage.getItem('accessToken');
    if (accessToken) {
      setSession(accessToken);
      dispatch(actions.getCurrentUserStart());
    } else {
      setIsAuthenticated(false);
      setIsInitialized(true);
      setSession(null);
    }
  }, []);

  // run every time auth redux state change.
  useEffect(() => {
    if (!auth.loading && userStore !== null) {
      // got the data
      setIsAuthenticated(true);
      setUser(userStore);
      setError(null);
      setIsInitialized(true);
    } else if (!auth.loading && userStore === null && auth.error !== null) {
      setIsAuthenticated(false);
      setUser(null);
      setError(auth.error);
      setIsInitialized(true);
    } else if (auth.loading) {
      // loading
      setError(null);
    }
  }, [auth]);

  // run every time login redux state change.
  useEffect(() => {
    if (!login.loading && userStore !== null) {
      setIsAuthenticated(true);
      setUser(userStore);
      setError(null);
      setIsInitialized(true);
    } else if (!login.loading && userStore === null && login.error !== null) {
      setIsAuthenticated(false);
      setUser(null);
      setError(login.error);
      setIsInitialized(true);
    } else if (login.loading) {
      // loading
      setError(null);
    }
  }, [login]);

  // this is a safe net to make sure whenever anyone remove accesstoken, we will just log user out.
  // in theory this will work in the future as well when token can be expired.
  // come back to test this when token has "expire" feature.
  // IMPORTANT:
  // this might looks confusing later but it is used for all other tabs opened of the app but not the current tab that trigger the action of logging out.
  useEffect(() => {
    function checkAuthLocalStorage() {
      const accessToken = window.localStorage.getItem('accessToken');
      // important, has to be && , not ||
      // because values will be set one by one, not at the same time.
      if (!accessToken) {
        onLogout();
      }
    }
    window.addEventListener('storage', checkAuthLocalStorage);
    return () => {
      window.removeEventListener('storage', checkAuthLocalStorage);
    };
  }, []);

  // this is used for the current tab that triggered the log out itself.
  // other open tabs of the app will log out based on the useEffect right about this one.
  useEffect(() => {
    if (triggerLogOutBecauseOfInvalidAccessToken !== 0) {
      const accessToken = window.localStorage.getItem('accessToken');
      if (!accessToken) {
        onLogout();
      }
    }
  }, [triggerLogOutBecauseOfInvalidAccessToken]);

  const onLogout = () => {
    setSession(null);
    setIsAuthenticated(false);
  };

  const onLogin = (email, password) => {
    dispatch(actions.postLoginStart({email, password}));
  };

  const onResetPassword = () =>
    // password: string
    {
      // get current user id
      // update password
      // return and display message.
      // use this props resetPasswordError
    };

  return (
    <AuthContext.Provider
      value={{
        ...initialState,
        isAuthenticated,
        isInitialized,
        user,
        error,
        resetPasswordError,
        onLogout,
        onLogin,
        onResetPassword,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export {AuthContext, AuthProvider};
