import React, {useEffect, useState} from 'react';
import {useSelector, RootStateOrAny} from 'react-redux';
import {Backdrop, CircularProgress} from '@material-ui/core';

const BackDropSpinner: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);

  // template
  const deleteTemplatePending = useSelector(
    ({template}: RootStateOrAny) => template.templateList.delete.pending
  );

  // company
  const postCompanyPending = useSelector(
    ({company}: RootStateOrAny) => company.company.create.pending
  );
  const putCompanyPending = useSelector(
    ({company}: RootStateOrAny) => company.company.update.pending
  );
  const deleteCompanyPending = useSelector(
    ({company}: RootStateOrAny) => company.companyList.delete.pending
  );

  // location
  const postLocationPending = useSelector(
    ({location}: RootStateOrAny) => location.location.create.pending
  );
  const putLocationPending = useSelector(
    ({location}: RootStateOrAny) => location.location.update.pending
  );
  const deleteLocationPending = useSelector(
    ({location}: RootStateOrAny) => location.locationList.delete.pending
  );

  // inspection
  const postInspectionPending = useSelector(
    ({inspection}: RootStateOrAny) => inspection.inspection.create.pending
  );
  const putInspectionPending = useSelector(
    ({inspection}: RootStateOrAny) => inspection.inspection.update.pending
  );
  const deleteInspectionPending = useSelector(
    ({inspection}: RootStateOrAny) => inspection.inspectionList.delete.pending
  );
  const putInspectionStatusPending = useSelector(
    ({inspection}: RootStateOrAny) => inspection.inspection.status.pending
  );

  // account settings
  const postAccSettingsPending = useSelector(
    ({accSettings}: RootStateOrAny) => accSettings.create.pending
  );
  const putAccSettingsPending = useSelector(
    ({accSettings}: RootStateOrAny) => accSettings.update.pending
  );

  useEffect(() => {
    const isNewOpen =
      deleteTemplatePending ||
      postCompanyPending ||
      putCompanyPending ||
      deleteCompanyPending ||
      postLocationPending ||
      putLocationPending ||
      deleteLocationPending ||
      postInspectionPending ||
      putInspectionPending ||
      deleteInspectionPending ||
      putInspectionStatusPending ||
      postAccSettingsPending ||
      putAccSettingsPending;

    if (isOpen !== isNewOpen) {
      setIsOpen(isNewOpen);
    }
  });

  return (
    <Backdrop
      sx={{color: '#fff', zIndex: theme => theme.zIndex.drawer + 1}}
      open={isOpen}
    >
      <CircularProgress />
    </Backdrop>
  );
};

export default BackDropSpinner;
