// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/';
const ROOTS_DASHBOARD = '/dashboard';

// ----------------------------------------------------------------------

export const PATH_PAGE = {
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  pricing: '/pricing',
  payment: '/payment',
  about: '/about-us',
  contact: '/contact-us',
  faqs: '/faqs',
  page404: '/404',
  page500: '/500',
  components: '/components',
  report: '/inspection/report',
};

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  register: path(ROOTS_AUTH, '/register'),
  registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  forgotPassword: path(ROOTS_AUTH, '/forgot-password'),
  registerConfirmation: path(ROOTS_AUTH, '/register-success'),
  verify: path(ROOTS_AUTH, '/verify'),
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  home: path(ROOTS_DASHBOARD, '/home'),
  actions: path(ROOTS_DASHBOARD, '/actions'),
  users: path(ROOTS_DASHBOARD, '/users'),
  templates: {
    root: path(ROOTS_DASHBOARD, '/templates'),
    list: path(ROOTS_DASHBOARD, '/templates'),
    item: path(ROOTS_DASHBOARD, '/templates/item/:id'),
    new: path(ROOTS_DASHBOARD, '/templates/new'),
  },
  inspections: {
    root: path(ROOTS_DASHBOARD, '/inspections'),
    list: path(ROOTS_DASHBOARD, '/inspections'),
    item: path(ROOTS_DASHBOARD, '/inspections/item/:id'),
    new: path(ROOTS_DASHBOARD, '/inspections/new'),
  },
  companies: {
    root: path(ROOTS_DASHBOARD, '/companies'),
    list: path(ROOTS_DASHBOARD, '/companies'),
    item: path(ROOTS_DASHBOARD, '/companies/item/:id'),
    new: path(ROOTS_DASHBOARD, '/companies/new'),
  },
  locations: {
    root: path(ROOTS_DASHBOARD, '/locations'),
    list: path(ROOTS_DASHBOARD, '/locations'),
    item: path(ROOTS_DASHBOARD, '/locations/item/:id'),
    new: path(ROOTS_DASHBOARD, '/locations/new'),
  },
  settings: path(ROOTS_DASHBOARD, '/settings'),
  // settings: {
  //   root: path(ROOTS_DASHBOARD, '/settings'),
  //   personal: path(ROOTS_DASHBOARD, '/settings/personal'),
  //   payment: path(ROOTS_DASHBOARD, '/settings/payment'),
  // },
};

export const PATH_DOCS = 'https://docs-minimals.vercel.app/introduction';
