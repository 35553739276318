//
// Auth apis - mock
//
export const userDataApi = '/apiaa/account/my-account';

// inspection app api endpoints
// export const rootUrl = 'https://api.staging.siteinspectionreporting.com.au/api';
export const rootUrl = 'https://api.production.siteinspectionreporting.com.au/api';
export const apiVersion = '/v1';
export const apiUrl = rootUrl + apiVersion;

// auth
export const currentUserApi = apiUrl + '/auth/user';
export const loginApi = apiUrl + '/login';

// template
export const templateApi = apiUrl + '/template';
export const containerApi = apiUrl + '/container';
export const sectionApi = apiUrl + '/section';
export const fieldApi = apiUrl + '/input_field';

// location
export const locationApi = apiUrl + '/location';

// company
export const companyApi = apiUrl + '/company';

// inspection
export const inspectionApi = apiUrl + '/inspection';

// account settings
export const accSettingsApi = apiUrl + '/account_setting';

// user
export const userApi = apiUrl + '/user';
