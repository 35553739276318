import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RegistrationStateType} from './types';

const initialState: RegistrationStateType = {
  registerationuser: {
    id: '',
    firstname: '',
    lastname: '',
    company: '',
    email: '',
    password: '',
    verifycode: 0,
    address: '',
    contactNumber: '',
  },
  registerStatus: {
    loading: false,
    emailError: null,
    verifyCodeError: null,
    detailError: null,
    success: false,
  },
  registerFormCurrentStep: 0,
};

const registerSlice = createSlice({
  name: 'register',
  initialState,
  reducers: {
    postRegisterEmailStart: (state, action) => {
      state.registerStatus = {
        ...state.registerStatus,
        loading: true,
      };
    },
    postRegisterEmailFail: (state, action) => {
      const {error} = action.payload;
      state.registerStatus = {
        ...state.registerStatus,
        loading: false,
        emailError: error,
      };
    },
    postRegisterEmailSuccess: (state, action) => {
      const {email, id} = action.payload;
      state.registerationuser = {
        ...state.registerationuser,
        id,
        email,
      };
      state.registerStatus = {
        ...state.registerStatus,
        loading: false,
        emailError: null,
      };
      state.registerFormCurrentStep = 1;
    },
    postEmailVerificationStart: (state, action) => {
      state.registerStatus = {
        ...state.registerStatus,
        loading: true,
      };
    },
    postEmailVerificationSuccess: (state, action) => {
      const {verifycode} = action.payload;
      state.registerationuser = {
        ...state.registerationuser,
        verifycode,
      };
      state.registerStatus = {
        ...state.registerStatus,
        loading: false,
        verifyCodeError: null,
      };
      state.registerFormCurrentStep = 2;
    },
    postEmailVerificationFail: (state, action) => {
      const {error} = action.payload;
      state.registerStatus = {
        ...state.registerStatus,
        loading: false,
        verifyCodeError: error,
      };
    },
    postRegisterUserDetailStart: (state, action) => {
      state.registerStatus = {
        ...state.registerStatus,
        loading: true,
        success: false,
      };
    },
    postRegisterUserDetailSuccess: (state, action) => {
      state.registerStatus = {
        ...state.registerStatus,
        loading: false,
        success: true,
      };
    },
    postRegisterUserDetailFail: (state, action) => {
      const {error} = action.payload;
      state.registerStatus = {
        ...state.registerStatus,
        loading: false,
        detailError: error,
        success: false,
      };
    },
    resetRegistrationState: state => initialState,
  },
});

const {actions, reducer} = registerSlice;

export {actions, reducer};
